<template>
	<b-card no-body>
		<b-card-header class="pb-50">
			<h5>
				Filters
			</h5>
		</b-card-header>
		<b-card-body>
			<b-row>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Login Name</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="usernameFilter"
						class="w-100"
						@input="(val) => $emit('update:usernameFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>User Id</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="userIdFilter"
						class="w-100"
						@input="(val) => $emit('update:userIdFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Count User</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="countUserFilter"
						class="w-100"
						@input="(val) => $emit('update:countUserFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Amount</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="amountFilter"
						class="w-100"
						@input="(val) => $emit('update:amountFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Created From</label>
					<b-input-group>
						<b-form-input
							:value="fromdateFilter"
							placeholder="YYYY-MM-DD"
							autocomplete="off"
							show-decade-nav
							@input="(val) => $emit('update:fromdateFilter', val)"
						/>
						<b-input-group-append>
							<b-form-datepicker
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:value="fromdateFilter"
								class="w-100"
								show-decade-nav
								button-only
								button-variant="outline-primary"
								right
								size="sm"
								locale="en-US"
								@context="onContextFromDate"
							/>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Created To</label>
					<b-input-group>
						<b-form-input
							:value="todateFilter"
							placeholder="YYYY-MM-DD"
							autocomplete="off"
							show-decade-nav
							@input="(val) => $emit('update:todateFilter', val)"
						/>
						<b-input-group-append>
							<b-form-datepicker
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:value="todateFilter"
								class="w-100"
								show-decade-nav
								button-only
								button-variant="outline-primary"
								right
								size="sm"
								locale="en-US"
								@context="onContextToDate"
							/>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormDatepicker, BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		vSelect,
	},
	props: {
		usernameFilter: {
			type: [String, null],
			default: null,
		},
		userIdFilter: {
			type: [String, null],
			default: null,
		},
		amountFilter: {
			type: [String, null],
			default: null,
		},
		countUserFilter: {
			type: [String, null],
			default: null,
		},
		fromdateFilter: {
			type: [String, null],
			default: null,
		},
		todateFilter: {
			type: [String, null],
			default: null,
		},
	},
	methods: {
		onContextFromDate(ctx) {
			this.$emit('update:fromdateFilter', ctx.selectedYMD)
			// this.selected = ctx.selectedYMD
		},
		onContextToDate(ctx) {
			this.$emit('update:todateFilter', ctx.selectedYMD)
			// this.selected = ctx.selectedYMD
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
